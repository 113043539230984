* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: linear-gradient(180deg, #64BEFF -50%, #6e96ff 69.71%, #2a65ff 150%);
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-container {
    position: relative;
    width: 240px;
    height: 208px;
    background: url(./images/map.png) no-repeat no-repeat;
    transform: scale(3);
    image-rendering: pixelated;
}